import React from "react";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';
import { fonts } from '../constants/fonts'

function CategoryName({ title, id }) {
  return (
    <div id={id} style={{
        fontSize: 22,
        color: colors.text,
        paddingTop: 10,
        paddingBottom: 0,
        height: 76,
        fontFamily: fonts.bold,
        paddingLeft: 30,
        paddingRight: 30,
      }}>
      <p style={{fontFamily: global.headerFont}}>{title}</p>
    </div>
  )
}


export default CategoryName


//     <TouchableOpacity
//      onPress={onPress}
//      style={{ backgroundColor: '#242424', paddingVertical: 12, paddingHorizontal: 20, marginRight: 10, marginVertical: 20, borderRadius: 20}}>
//      <Text style={{color: colors.white, fontSize: 14 , fontFamily: fonts.bold }} adjustsFontSizeToFit allowFontScaling>{title}</Text>
//    </TouchableOpacity>
