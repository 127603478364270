// Firebase Configuration

const firebaseCompanyConfig = {
  apiKey: "AIzaSyBocrP0EU8l5PxY84A0Cv0tMPWSPxvG98E",
  authDomain: "fyre-amontola.firebaseapp.com",
  projectId: "fyre-amontola",
  storageBucket: "fyre-amontola.appspot.com",
  messagingSenderId: "546298019238",
  appId: "1:546298019238:web:0abdf0ca66181c1f4b2367",
  measurementId: "G-NE6927V37R"
};

const firebaseBackOfficeConfig = {
  apiKey: "AIzaSyB9HTMpNuz5XN9Y2rHmGOnVTPIu2Ea10dQ",
  authDomain: "fyre-pos.firebaseapp.com",
  databaseURL: "https://fyre-pos.firebaseio.com",
  projectId: "fyre-pos",
  storageBucket: "fyre-pos.appspot.com",
  messagingSenderId: "179766960980",
  appId: "1:179766960980:web:9779b88a82add54ae8dec1",
  measurementId: "G-BJCJW0ZNS1"
};


export { firebaseCompanyConfig, firebaseBackOfficeConfig }
