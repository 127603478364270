import React from "react";
import {
    View,
    Text,
    StyleSheet,
    Platform,
    Image
} from "react-native";

import Icon from 'react-native-vector-icons/Ionicons';

import { colors } from '../constants/colors';

const HeaderWeb = (props) => {
    return (
      <View style={{ flexDirection: "row"}}>
        <Image
          resizeMode="contain"
          style={{
                width: 200,
                height: 80,
              }}
          source={require('../assets/amontola-logo-2-web.png')}
        />
      </View>
  )
}

export default HeaderWeb

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
});
