import * as firebase from "firebase";

import {connect, reduce, getState, useDispatch} from 'react-redux';
import { showMessage, hideMessage } from "react-native-flash-message";

import moment from "moment";
import axios from 'axios'


const resetCart = () => {
  return {
    type: "RESET_CART",
  }
}

const addPayment = (data) => {
  return {
    type: "ADD_PAYMENT",
    payload: data,
  }
}

const addTransactionNo = (data) => {
  return {
    type: "ADD_TRANSACTION_NO",
    payload: data,
  }
}

const addOrderNo = (data) => {
  return {
    type: "ADD_ORDER_NO",
    payload: data,
  }
}

const addOrderId = (data) => {
  return {
    type: "ADD_ORDER_ID",
    payload: data,
  }
}

function MergeDuplicateItems(i) {

  // This Merge Duplicate Items is for online orders only.
  let items = JSON.parse(JSON.stringify([...i]))

  // This finds items that user has duplicated without changing quantity and merges them.
  let mergedItems = items.reduce((accumulator, object) => {

    let objectFound = []

    if (objectFound = accumulator.find(arrItem => arrItem.pid === object.pid && arrItem.note === object.note && arrItem.name === object.name && arrItem.damount === object.damount && arrItem.dtype === object.dtype && JSON.stringify(arrItem.variant) === JSON.stringify(object.variant) && JSON.stringify(arrItem.modifiers) === JSON.stringify(object.modifiers)) ) {
        // Finds to see if it has same Product ID, Note, Discount Amount & Type, Variant and Modiifiers
        objectFound.quantity = objectFound.quantity + object.quantity;
    } else {
        // If can't find an existing item, add it in.
        accumulator.push(object);
    }

    return accumulator

  }, []);

  return mergedItems
}

async function createOrder(cartData, totalPrice, user, dispatch, transactionNo, isPaid, branch) {

  const userData = user

  // Get Timestamp
  const timestamp = firebase.firestore.FieldValue.serverTimestamp();
  const timestampEmail = new Date()

  //Assigns detail orderNo
  let orderNo = 1
  let orderId = null
  let timeSlot = null
  let customer = {}
  let discount = null
  let isASAP = false
  let res = 0

  // Merges Items from Cart
  let items = MergeDuplicateItems(cartData.items)

  // Get Last Order ID - Adds 1.
  await global.backendFirebase.firestore().collection("/orders/" + global.companyId + "/order/")
    .where("branch", "==", global.branchId)
    .orderBy("timeCreated", "desc").limit(1)
    .get()
    .then(async function(querySnapshot) {


      if (branch.randomOrderNo != null | branch.randomOrderNo != undefined | branch.randomOrderNo != false) {
        // Random Order No
        orderNo = Math.floor(Math.random() * 1000);
      } else {
        // Finds previous order no then works out what to do with it.
        await querySnapshot.forEach(function(doc) {

            if (doc.data().orderNo != null || doc.data().orderNo == 999) {
              if (doc.data().orderNo != 999) {
                orderNo = doc.data().orderNo + 1
              }
            }

        });
      }

      // Checks to see whether order is Delivery or Collection
        if (cartData.type == 1 || cartData.type == 2) {

          // If there's no time or customer has selected ASAP it will create the order for mimum order time for branch.
          if (cartData.time == "" || cartData.time == "ASAP") {

            timeSlot = moment().add(branch.prepareTime, 'minutes')

            if (cartData.type == 1) {
              // Finds out if it's a delivery if so add delivery time
              timeSlot = moment(timeSlot).add(branch.deliveryTime, 'minutes')
            }

            timeSlot = new Date(timeSlot)
            isASAP = true

          } else {
            timeSlot = cartData.time
          }
        }

        if (transactionNo != "") {
          cartData.transactionNo = transactionNo
        }

        if (isPaid == null || isPaid == false) {
          cartData.isPaid = 0
        } else {
          cartData.isPaid = 1
        }

        if (cartData.type == 2) {

          // If user is logged in
          if (typeof userData == "undefined" || userData == null) {
            // If Guest
            customer = {
                firstName: cartData.customer.firstName ? cartData.customer.firstName : null,
                secondName: cartData.customer.secondName ? cartData.customer.secondName : null,
                phone: cartData.customer.phone ? cartData.customer.phone : null,
                email: cartData.customer.email ? cartData.customer.email : null,
            }
          } else {
            customer = {
                firstName: userData.firstName ? userData.firstName : null,
                secondName: userData.secondName ? userData.secondName : null,
                phone: userData.phone ? userData.phone : null,
                email: userData.email ? userData.email : null,
                id: userData.id ? userData.id : null
            }
          }


        } else {
          // If user is logged in
          if (typeof userData == "undefined" || userData == null) {
            customer = {
                firstName: cartData.customer.firstName ? cartData.customer.firstName : null,
                secondName: cartData.customer.secondName ? cartData.customer.secondName : null,
                phone: cartData.customer.phone ? cartData.customer.phone : null,
                email: cartData.customer.email ? cartData.customer.email : null,
                postcode: cartData.customer.postcode ? cartData.customer.postcode: null,
                line1: cartData.customer.line1 ? cartData.customer.line1 : null,
                line2: cartData.customer.line2 ? cartData.customer.line2 : null,
                town: cartData.customer.town ? cartData.customer.town : null,
                county: cartData.customer.county ? cartData.customer.county : null,
                location: cartData.customer.location[0] ? cartData.customer.location[0] : null,
            }
          } else {
            customer = {
                firstName: userData.firstName ? userData.firstName : null,
                secondName: userData.secondName ? userData.secondName : null,
                phone: userData.phone ? userData.phone : null,
                email: userData.email ? userData.email : null,
                postcode: cartData.customer.postcode ? cartData.customer.postcode: null,
                line1: cartData.customer.line1 ? cartData.customer.line1 : null,
                line2: cartData.customer.line2 ? cartData.customer.line2 : null,
                town: cartData.customer.town ? cartData.customer.town : null,
                county: cartData.customer.county ? cartData.customer.county : null,
                location: cartData.customer.location[0] ? cartData.customer.location[0] : null,
                id: userData.id ? userData.id : null
            }
          }
        }

        const data = {
          orderNo: orderNo,
          branch: global.branchId,
          transactionNo: cartData.transactionNo ? cartData.transactionNo : null,
          transactionId: cartData.transactionId ? cartData.transactionId : null,
          timeCreated: timestamp,
          totalPrice: totalPrice,
          table: {
            id: null,
            name: null,
            covers: null,
          },
          note: cartData.note ? cartData.note : null,
          timeSlot: timeSlot ? timeSlot : null,
          stripeId: cartData.stripeId ? cartData.stripeId : null,
          type: cartData.type,
          isPaid: cartData.isPaid,
          isVoid: false,
          isASAP: isASAP,
          platform: "Online",
          status: 1,
          discount: cartData.discount,
          customer: customer
        }

        await global.backendFirebase.firestore()
          .collection("/orders/" + global.companyId + "/order")
          .add(data)
          .then(async function(docRef) {

            // Add Order Items to the order.
            let colRef = global.backendFirebase.firestore()
              .collection("/orders/" + global.companyId + "/orderData")

            /// Batch Thing
            var batch = global.backendFirebase.firestore().batch();

            // Adds Id to CartData
            orderId = docRef.id

            items.map(item => {

              var name = item.name
              var price = parseFloat(item.price)
              var note = null
              var discountType = null
              var discountAmount = null
              var vid = null
              var modifiers = []

              if (item.variant) {
                name = item.variant.name + " " + item.name
                price = item.variant.price
                vid = item.variant.id
              }

              if (item.note) {
                note = item.note
              }

              if (item.damount) {
                discountAmount = item.damount,
                discountType = item.dtype
              }

              if (item.modifiers.length > 0) {
                modifiers = item.modifiers
              }

              batch.set(colRef.doc(), {
                order: orderId,
                branch: global.branchId,
                name: name,
                pid: item.pid,
                vid: vid,
                price: price,
                category: item.category,
                modifiers: modifiers,
                quantity: item.quantity,
                note: note,
                timestamp: timestamp,
                discountAmount: discountAmount,
                discountType: discountType,
                customer: {
                  id: userData != undefined ? userData.id : null
                }
              })

            });

            batch.commit()
            .catch((error) => {
              console.log("Error: " + JSON.stringify(error))
            });

            // Returns Order ID
            orderId = docRef.id
            return orderId

          })
          .catch((error) => {
            console.log("Error: " + JSON.stringify(error))
          });

          // Send Email!

          if (data.customer.email != null || data.customer.email != "") {
            // Send order confirmation

            data.items = cartData.items
            data.timeCreated = timestampEmail

            if (cartData.time == "") {
              data.timeSlot = new Date()
            } else {
              data.timeSlot = cartData.time
            }


            if (res == 0) {
              axios.post("https://splinter.cloud.yuze.uk/send-order-confirmation",
              {
                cart: data,
                branch: branch
              })
              .then(response => response.data)
              .catch(error => {
                console.log("Error: " + JSON.stringify(error))
              });
              res = 1
            }

          }

          return orderId

    })
    .catch(function(error) {
        console.log("Error getting documents: ", error);
    });

  return {
    orderId: orderId,
    orderNo: orderNo,
    customer: customer
  }

}

export { resetCart, createOrder, addPayment, addTransactionNo }
